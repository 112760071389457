.neonsign {
  width: 100%;
  display: flex;
}
.block,
.block#vanillalatte,
.modal-body {
  background-color: #98cdda;
  text-align: center;
  height: 32rem;
}

.block#cafelatte,
.block#handdrip,
#cafelatte .modal-body,
#handdrip .modal-body {
  background-color: #70575e;
}

.block#cafemocha,
.block#affogato,
#cafemocha .modal-body,
#affogato .modal-body {
  background-color: #d1a26c;
}
.block#whitemocha,
#whitemocha .modal-body {
  background-color: #536431;
}
.block#caramelmacchiato,
#caramelmacchiato .modal-body {
  background-color: #463232;
}
.block#affogato {
  height: 34rem;
}

.coffee-image {
  width: 40%;
}

.coffee-image#cafelatte {
  width: 32%;
  margin-top: 5rem;
  margin-bottom: 2.5rem;
  margin-left: 1rem;
}
.coffee-image#cafemocha,
.coffee-image#caramelmacchiato {
  width: 35%;
  margin-top: 6rem;
  margin-bottom: 3rem;
}

.coffee-image#whitemocha {
  width: 45%;
  margin-left: -1rem;
}

.coffee-image#vanillalatte {
  width: 40%;
  margin-top: 5rem;
  margin-bottom: 3rem;
}
.coffee-image#whitemocha {
  width: 50%;
  margin-top: -2rem;
  margin-bottom: 2rem;
}
.coffee-image#handdrip {
  width: 30%;
  margin-top: 6rem;
  margin-left: -3rem;
  margin-bottom: 3rem;
}
.coffee-image#affogato {
  width: 25%;
  margin-top: 4.5rem;
  margin-bottom: 3rem;
}
.drinkname {
  font-family: league spartan;
  font-weight: 600;
  font-size: 2rem;
  color: aliceblue;
}

.modal-body {
  background-color: #98cdda;
}
.drink-name {
  font-family: league spartan;
  color: white;
  font-size: 8rem;
  text-align: center;
  margin-top: -4rem;
}
.modal-coffee-image {
  width: 100%;
}

.modal-iced-coffee-image {
  width: 55%;
  margin-left: 2rem;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

#cafelatte .modal-coffee-image {
  margin-left: 2rem;
  margin-top: 8rem;
  width: 80%;
  top: 5rem;
}

#cafelatte .modal-iced-coffee-image {
  margin-bottom: 0rem;
}

#cafelatte .drink-name {
  margin-top: 2rem;
}

#cafemocha .modal-coffee-image {
  width: 80%;
  margin-left: -1rem;
  margin-top: 15rem;
  margin-bottom: 7rem;
}

#cafemocha .modal-iced-coffee-image {
  width: 40%;
  margin-left: 1rem;
  margin-bottom: 7rem;
}

#caramelmacchiato .modal-coffee-image {
  width: 90%;
  margin-top: 15rem;
  margin-bottom: 10rem;
}

#caramelmacchiato .drink-name {
  font-size: 6rem;
}

#caramelmacchiato .modal-iced-coffee-image {
  width: 45%;
  margin-left: 2rem;
  margin-top: 5rem;
  margin-bottom: 10.5rem;
}

#vanillalatte .modal-coffee-image {
  width: 90%;
  margin-top: 15rem;
  margin-bottom: 9rem;
}

#vanillalatte .modal-iced-coffee-image {
  width: 40%;
  margin-left: 1rem;
  margin-top: 5rem;
  margin-bottom: 6rem;
}

#whitemocha .modal-coffee-image {
  width: 120%;
  margin-top: -5rem;
  margin-bottom: 7rem;
  margin-left: -6rem;
}

#whitemocha .modal-iced-coffee-image {
  width: 45%;
  margin-left: 2rem;
  margin-top: 5rem;
  margin-bottom: 6rem;
}

#handdrip .modal-coffee-image {
  width: 90%;
  margin-top: 9rem;
  margin-bottom: 7rem;
  margin-left: -6rem;
}

#handdrip .modal-iced-coffee-image {
  width: 40%;
  margin-left: 2rem;
  margin-top: 7rem;
  margin-bottom: 6rem;
}

#affogato .modal-coffee-image {
  width: 60%;
  margin-top: 15rem;
  margin-bottom: 12rem;
  margin-left: 2rem;
}

.modal-body .btn-close {
  position: absolute;
  font-size: 4rem;
  top: 5rem;
  left: 51rem;
}

.Button .btn.btn-primary {
  text-align: center;
  margin-top: 3rem;
  font-size: 5rem;
  padding-top: 3.5rem;
  width: 25rem;
  height: 15rem;
  border-radius: 4rem !important;
  margin-left: 0.5rem;
  --bs-btn-color: #3f2e37;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #3f2e37;
  --bs-btn-hover-border-color: #3f2e37;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3f2e37;
  --bs-btn-active-border-color: #3f2e37;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #fff;
  --bs-btn-disabled-border-color: #fff;
}

.Button .btn.btn-primary.order-button {
  width: 55.5rem;
  padding-top: 0rem;
  height: 10rem;
}

.boxshadow {
  box-shadow: 0 5px 50px 0 rgba(0, 0, 0, 0.2), 0 50px 50px 0 rgba(0, 0, 0, 0.19);
}

.hiddenimage {
  display: none;
}

.insideModal .modal-dialog {
  margin-top: 64rem;
  max-width: 100%;
  font-size: 5rem;
}
.insideModal .modal-content {
  border-radius: 10rem 10rem 0 0;
}

.submitform {
  height: 40rem;
}

.form.form-control.form-control-lg {
  margin-top: 12rem;
  text-align: center;
  font-size: 4.5rem;
  margin-bottom: 2rem;
  border: 0;
}

.mb-2.btn.btn-primary {
  text-align: center;
  font-size: 5rem;
  padding-top: 0rem;
  margin-left: 1.5rem;
  width: 95%;
  height: 10rem;
  color: white;
  --bs-btn-color: #3f2e37;
  --bs-btn-bg: #3f2e37;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #3f2e37;
  --bs-btn-hover-border-color: #3f2e37;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3f2e37;
  --bs-btn-active-border-color: #3f2e37;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #fff;
  --bs-btn-disabled-border-color: #fff;
}
