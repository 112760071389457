p {
  padding-top: 350px;
  text-align: center;
  font-size: 30px;
  font-family: league spartan;
  font-weight: 600;
}
.main {
  background-color: #ff7e7e;
  height: 100vh;
}
.img {
  width: 100%;
  position: absolute;
  top: 23rem;
}
.img2 {
  width: 100%;
  position: absolute;
  top: 53rem;
}
