.accordion {
  color: rgb(51, 51, 51);
  cursor: pointer;
  font-size: 4rem;
  padding: 2vh;
  width: 100%;
  text-align: center;
  border: none;
  outline: none;
  transition: 0.9s;
  font-family: league spartan;
  font-weight: 600;
  border-radius: 50px 50px 0 0;
}

.outer {
  margin-top: -1px;
}

.neonsign {
  width: 100%;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active,
.accordion:hover {
  background-color: relative;
  border: none;
  outline: none;
}

/* Style the accordion panel. Note: hidden by default */
.panel {
  font-family: "News Cycle";
  padding: 0 4rem;
  max-height: 0;
  overflow: hidden;
  height: 100%;
  margin-top: -3px;
  border: none;
  outline: none;
  transition: max-height 0.2s ease-out;
  position: relative;
}

.ingredients {
  margin-top: 30px;
  margin-left: 10px;
  text-align: left;
  font-size: 3rem;
  font-weight: bold;
}

.details {
  font-size: 2.5rem;
  text-align: left;
  padding: 5rem;
}

.picture {
  margin-left: 2rem;
}

.picture-wrapper {
  display: block;
  text-align: center;
}

/*individual picture adjustment*/

.ontherock {
  height: 30rem;
  margin-left: 0rem;
}

.highball {
  height: 40rem;
  margin: 0rem;
}

.flute {
  margin-left: -2rem;
  height: 35rem;
}

.highball#mojito {
  margin-left: 2rem;
}

.highball#midorisour {
  margin-left: 0rem;
}

.coupe {
  margin-left: 0;
}

.coupe#amarettosour {
  margin-left: 2rem;
}
.coupe#EspressoMartini {
  margin-left: 2rem;
  width: 500px;
}

.martini {
  margin-left: 4rem;
}

.martini#whiskeysour {
  margin-left: 0rem;
}

.martini#JapaneseSlipper {
  margin-left: 0rem;
}

.julep {
  margin-left: 4rem;
}

.popup {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: flex-end;
  background-color: bisque;
  border-radius: 40px;
}
.SubmitButton {
  padding: 10px;
}

.SubmitButton .mb-2.btn.btn-primary {
  margin: 0px;
  border-radius: 20px;
  height: 5rem;
  font-size: 2rem;
}

.customModal .modal-content {
  position: absolute;
  top: 50vh;
  width: 50vw;
  background-color: transparent;
}

.searchbar.form-control.form-control-lg {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 5rem;
  border-radius: 30px;
  padding: 20px;
  width: 80%;
  text-align: center;
  margin-bottom: 20px;
  font-size: 40px;
}

.search {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 1s;
  width: 100px;
  height: 100px;
  background: transparent;
  box-sizing: border-box;
  border-radius: 4px;
  border: 4px solid transparent;
  padding: 5px;
}

.searchinput {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 80px;
  line-height: 30px;
  outline: 0;
  border: 0;
  font-size: 2em;
  border-radius: 20px;
  padding: 0 41px;
  background-color: white;
}

.fa {
  box-sizing: border-box;
  padding: 8px;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 4px;
  color: #07051a;
  text-align: center;
  font-size: 1.2em;
  transition: all 1s;
}
.search:focus-within,
.search:hover,
.search:valid {
  width: 80%;
  cursor: pointer;
}

.search:hover .searchinput,
.search:focus-within .searchinput,
.search:valid .searchinput {
  width: 100%;
}

.search:hover .fa,
.search:focus-within .fa,
.search:valid .fa {
  background: #07051a;
  color: white;
  top: 0px;
}
.fa-search:before {
  font-size: 70px;
}

.fa.fasearch {
  top: 10px;
}
